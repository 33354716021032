<template>
  <div id="card-body-wrapper" class="card-body">
    <div
      class="d-flex align-items-center justify-content-center mb-4"
      v-if="page"
    >
      <div class="mx-1">Page</div>
      <a-select class="mx-1" v-model="selectPage">
        <a-select-option v-for="n in totalPage" :value="n" :key="n">{{
          n
        }}</a-select-option>
        <a-select-option value="rewardPunishment"
          >Reward and Punishment Report</a-select-option
        >
      </a-select>
      <template v-if="selectPage != 'rewardPunishment'">
        <div class="mx-1">Of</div>
        <div class="mx-1">{{ totalPage }}</div>
      </template>
    </div>
    <div v-if="dataReport" ref="pdfContent" id="pdf-content">
      <!-- {{dataReport.report_mapels}} -->
      <div
        v-if="!page || (page && selectPage === 1)"
        :style="{ ...format, position: 'relative' }"
        class="font-size-12"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img
              :src="institusi ? institusi.logo : `/${configSchool.logo}`"
              width="120px"
              alt
            />
          </div>
          <div class="ml-5">
            <h3 class="text-uppercase font-size-30" v-if="kumer">
              Student Progress Report
            </h3>
            <h3 class="text-uppercase font-size-30" v-else>
              Student Progress Report
            </h3>
          </div>
        </div>
        <hr />
        <table class="table table-borderless mt-3">
          <tr class="font-size-12">
            <td class="table-title py-1">School Name</td>
            <td class="py-1">:</td>
            <td class="table-value-left py-1">
              {{ institusi ? institusi.nama : configSchool.name2 }}
            </td>
            <td class="table-title py-1">Class</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.murid.kelas }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title py-1">Address</td>
            <td class="py-1">:</td>
            <td class="table-value-left py-1" style="width: 35%">
              {{ institusi ? institusi.alamat.lokasi : configSchool.address }}
            </td>
            <td class="table-title py-1">Academic</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.tahun_ajaran.tahun }} /
              {{ dataReport.tahun_ajaran.semester }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title font-size-12 py-1">Student Name</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.murid.nama }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title font-size-12 py-1">NIS</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.murid.nis || '-' }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title py-1">NISN</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.murid.nisn || '-' }}
            </td>
          </tr>
        </table>
        <hr />
        <div class="mt-5 font-size-12">
          <ol class="ol-alphabet">
            <li class="font-weight-bold text-uppercase">
              <h5>Attitude</h5>
            </li>
            <div class="mt-2">
              <h6 class="text-uppercase">1. Spiritual Attitude</h6>
              <table class="table table-bordered text-center mt-1">
                <tr>
                  <td class="w-25">Predicate</td>
                  <td class="w-75">Description</td>
                </tr>
                <tr>
                  <td class="align-middle">
                    <!-- {{
                      checkString("spiritual", dataReport.spiritual).alias
                    }} -->
                    {{ spiritualAttitude.predicate || '-' }}
                  </td>
                  <td>
                    <!-- {{
                      checkString("spiritual", dataReport.spiritual)
                        .description
                    }} -->
                    {{ spiritualAttitude.description || '-' }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="mt-2">
              <h6 class="text-uppercase">2. Social Attitude</h6>
              <table class="table table-bordered text-center mt-1">
                <tr>
                  <td class="w-25">Predicate</td>
                  <td class="w-75">Description</td>
                </tr>
                <tr>
                  <td>
                    <!-- {{
                      checkString("social", dataReport.socialPredicateOption)
                        .alias
                    }} -->
                    {{ socialAttitude.predicate || '-' }}
                  </td>
                  <td>
                    <!-- {{
                      checkString("social", dataReport.socialPredicateOption)
                        .description
                    }} -->
                    {{ socialAttitude.description || '-' }}
                  </td>
                </tr>
              </table>
            </div>
          </ol>
        </div>
        <div
          v-if="print"
          style="
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 10px;
          "
        >
          <hr class="mb-1" />
          <Footer
            :nama="dataReport.murid.nama"
            :kelas="dataReport.murid.kelas"
            :semester="dataReport.tahun_ajaran.semester"
            :page="1"
            :totalPage="totalPage"
          />
        </div>
      </div>

      <!-- <template
        v-if="!page || (page && (selectPage === 2 || selectPage === 3))"
      > -->
      <!-- page 2 - tergantung group knowledge -->
      <template
        v-if="
          !page ||
          (page &&
            selectPage > 1 &&
            selectPage < dataReport.knowledge_skill.knowledge.length + 2)
        "
      >
        <div
          v-for="(item, i) in dataReport.knowledge_skill.knowledge"
          :key="i + 'table_knowledge'"
          :style="
            !page ? { ...format, position: 'relative', marginTop: '20px' } : {}
          "
          class="font-size-12"
        >
          <!-- margin -->
          <!-- <div v-if="!page" style="height: 50px" /> -->
          <ol
            v-if="(!page && i === 0) || (selectPage === 2 && i === 0)"
            class="ol-alphabet"
            :start="2"
          >
            <li class="font-weight-bold text-uppercase">
              <h5 v-if="!kumer">Knowledge and skill</h5>
              <h5 v-else>Knowledge</h5>
            </li>
          </ol>
          <div class="mt-2 pl-5">
            <h6
              v-if="
                ((!page && i === 0) || (selectPage === 2 && i === 0)) && !kumer
              "
              class="text-uppercase"
            >
              1. Knowledge
            </h6>
            <table
              v-if="!page || selectPage === i + 2"
              class="table table-bordered mt-1"
            >
              <tr class="text-center">
                <td class="align-middle" rowspan="2">No.</td>
                <td class="align-middle w-25" rowspan="2">Subject</td>
                <td class="align-middle" rowspan="2" v-if="!kumer">KBM</td>
                <!-- <td class="align-middle" rowspan="2" v-else>CLASS AVERAGE</td> -->
                <td colspan="3" rowspan="1">Knowledge</td>
              </tr>
              <tr class="text-center">
                <td rowspan="1">Grade</td>
                <td rowspan="1">Remark</td>
                <td rowspan="1">Description</td>
              </tr>
              <tr v-if="!kumer">
                <td class="font-size-12" colspan="7">
                  Group {{ item.nama_group }}
                </td>
              </tr>
              <tr
                :style="{ height: !page ? tableGradeHeight + 'px' : null }"
                v-for="(mapel, index) in item.mata_pelajaran"
                :key="index"
              >
                <td class="align-middle text-center">
                  {{ numberIndex(index, i) }}
                </td>
                <td class="align-middle">
                  {{ mapel.nama_mata_pelajaran || '-' }}
                </td>
                <td class="align-middle text-center" v-if="!kumer">
                  {{ mapel.kbm || '-' }}
                </td>
                <!-- <td class="align-middle text-center" v-else>
                  {{
                    mapel.classAvg || '-'
                  }}
                </td> -->
                <td class="align-middle text-center">
                  <!-- {{
                        mapel.nilai_pengetahuan !== null
                          ? Math.round(mapel.nilai_pengetahuan)
                          : "-"
                      }} -->
                  {{ roundNumber(mapel.grade) || '-' }}
                </td>
                <td class="align-middle text-center">
                  <!-- {{
                        scoring(
                          mapel.nilai_pengetahuan,
                          mapel.mata_pelajaran.kbms
                        ).remark
                      }} -->
                  {{ mapel.remark || '-' }}
                </td>
                <td class="text-left pl-2 align-middle" style="font-size: 10px">
                  <!-- {{
                        checkString(
                          "knowledge",
                          scoring(
                            mapel.nilai_pengetahuan,
                            mapel.mata_pelajaran.kbms
                          ).remark
                        ).description
                      }} -->
                  {{ mapel.description || '-' }}
                </td>
              </tr>
            </table>
          </div>
          <div
            v-if="print"
            style="
              position: absolute;
              bottom: 0;
              width: 100%;
              margin-bottom: 10px;
            "
          >
            <hr class="mb-1" />
            <Footer
              :nama="dataReport.murid.nama"
              :kelas="dataReport.murid.kelas"
              :semester="dataReport.tahun_ajaran.semester"
              :page="i + 2"
              :totalPage="totalPage"
            />
          </div>
        </div>
      </template>

      <!-- page 3 -->
      <!-- </template> -->

      <!-- page skill group - multiple -->
      <template
        v-if="
          (!page ||
            (page &&
              selectPage > dataReport.knowledge_skill.knowledge.length + 1 &&
              selectPage < totalPageForSkillAndKnowledge + 2)) &&
          !kumer
        "
      >
        <div
          v-for="(item, i) in dataReport.knowledge_skill.skill"
          :key="i + 'table_skill'"
          :style="
            !page ? { ...format, position: 'relative', marginTop: '20px' } : {}
          "
          class="font-size-12"
        >
          <div class="pl-5">
            <h6
              v-if="
                (!page && i === 0) ||
                (selectPage ===
                  dataReport.knowledge_skill.knowledge.length + 2 &&
                  i === 0)
              "
              class="text-uppercase"
            >
              2. Skill
            </h6>
            <table
              class="table table-bordered mt-1"
              v-if="
                !page ||
                selectPage ===
                  i + dataReport.knowledge_skill.knowledge.length + 2
              "
            >
              <tr class="text-center">
                <td class="align-middle" rowspan="2">No.</td>
                <td class="align-middle w-25" rowspan="2">Subject</td>
                <td class="align-middle" rowspan="2">KBM</td>
                <td colspan="3" rowspan="1">Skill</td>
              </tr>
              <tr class="text-center">
                <td rowspan="1">Grade</td>
                <td rowspan="1">Remark</td>
                <td rowspan="1">Description</td>
              </tr>
              <tr>
                <td class="font-size-12" colspan="7">
                  Group {{ item.nama_group }}
                </td>
              </tr>
              <tr
                v-for="(mapel, index) in item.mata_pelajaran"
                :key="index"
                :style="{ height: !page ? tableGradeHeight + 'px' : null }"
              >
                <td class="align-middle text-center">
                  {{ numberIndex(index) }}
                </td>
                <td class="align-middle">
                  {{ mapel.nama_mata_pelajaran }}
                </td>
                <td class="align-middle text-center">
                  {{ mapel.kbm || '-' }}
                </td>
                <td class="align-middle text-center">
                  {{ roundNumber(mapel.grade) || '-' }}
                </td>
                <td class="align-middle text-center">
                  {{ mapel.remark || '-' }}
                </td>
                <td class="text-left pl-2 align-middle" style="font-size: 10px">
                  {{ mapel.description || '-' }}
                </td>
              </tr>
            </table>
          </div>
          <div
            v-if="print"
            style="
              position: absolute;
              bottom: 0;
              width: 100%;
              margin-bottom: 10px;
            "
          >
            <hr class="mb-1" />
            <Footer
              :nama="dataReport.murid.nama"
              :kelas="dataReport.murid.kelas"
              :semester="dataReport.tahun_ajaran.semester"
              :page="dataReport.knowledge_skill.knowledge.length + i + 2"
              :totalPage="totalPage"
            />
          </div>
        </div>
      </template>

      <!-- page 5 -->
      <!-- <div
        v-if="!page || (page && selectPage === 5)"
        :style="{ ...format, position: 'relative' }"
        class="font-size-12"
      >
        <div class="pl-5">
          <table class="table table-bordered mt-1">
            <tr class="text-center">
              <td class="align-middle" rowspan="2">No.</td>
              <td class="align-middle w-25" rowspan="2">Subject</td>
              <td class="align-middle" rowspan="2">KBM</td>
              <td colspan="3" rowspan="1">Skill</td>
            </tr>
            <tr class="text-center">
              <td rowspan="1">Grade</td>
              <td rowspan="1">Remark</td>
              <td rowspan="1">Description</td>
            </tr>
            <tr>
              <td class="font-size-12" colspan="7">Group B</td>
            </tr>
            <tr
              v-for="(mapel, index) in mapels(dataReport.report_mapels, 'B')"
              :key="mapel.id"
            >
              <td
                :style="{ height: !page ? tableGradeHeight + 'px' : null }"
                class="align-middle text-center"
              >
                {{ numberIndex(index) }}
              </td>
              <td class="align-middle">
                {{
                  mapel.mata_pelajaran.english_name !== null
                    ? mapel.mata_pelajaran.english_name
                    : mapel.mata_pelajaran.nama
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  mapel.mata_pelajaran.kbms.length
                    ? mapel.mata_pelajaran.kbms[0].nilai
                    : "-"
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  mapel.nilai_keterampilan !== null
                    ? Math.round(mapel.nilai_keterampilan)
                    : "-"
                }}
              </td>
              <td class="align-middle text-center">
                {{
                  scoring(mapel.nilai_keterampilan, mapel.mata_pelajaran.kbms)
                    .remark
                }}
              </td>
              <td class="align-middle text-left pl-2" style="font-size: 11px">
                {{
                  checkString(
                    "skill",
                    scoring(mapel.nilai_keterampilan, mapel.mata_pelajaran.kbms)
                      .remark
                  ).description
                }}
              </td>
            </tr>
          </table>
        </div>
        <div
          v-if="print"
          style="
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 40px;
          "
        >
          <hr class="mb-1" />
          <div class="d-flex justify-content-between mt-1 px-2">
            <Footer :nama="dataReport.murid.nama" :kelas="dataReport.murid.kelas" :semester="dataReport.tahun_ajaran.semester" :page="5" :totalPage="totalPage" />
          </div>
        </div>
      </div> -->

      <!-- page 6 -->
      <div
        v-if="!page || (page && selectPage === 6) || (kumer && selectPage == 4)"
        :style="{ ...format, position: 'relative', marginTop: '20px' }"
        class="font-size-12"
      >
        <!-- <div v-if="!page" style="height: 50px" /> -->
        <ol class="ol-alphabet" :start="3">
          <li class="font-weight-bold text-uppercase">
            <h5>Extracurricular</h5>
          </li>
          <div class="mt-2">
            <table class="table-bordered mt-1 w-100" style="font-size: 11px">
              <tr style="height: 30px" class="text-center">
                <td style="width: 10%">No</td>
                <td>Extracurricular</td>
                <td>Predicate</td>
                <td>Description</td>
              </tr>
              <template v-if="dataReport.extracurricular.length">
                <tr
                  style="height: 25px"
                  class="text-center"
                  v-for="(item, index) in dataReport.extracurricular"
                  :key="index"
                >
                  <td style="height: 20px">{{ numberIndex(index) }}</td>
                  <td>
                    {{ item.nama || '-' }}
                  </td>
                  <td>{{ item.predicate || '-' }}</td>
                  <td>
                    <!-- {{ checkString("extracurricular", item.nilai).description }} -->
                    {{ item.description || '-' }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr style="height: 25px" class="text-center">
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr style="height: 25px" class="text-center">
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr style="height: 25px" class="text-center">
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </template>
            </table>
          </div>
          <li class="font-weight-bold text-uppercase">
            <h5>Activities</h5>
          </li>
          <div class="mt-1">
            <table class="table-bordered mt-1 w-100" style="font-size: 11px">
              <tr style="height: 30px" class="text-center">
                <td style="width: 10%">No.</td>
                <td style="width: 27%">Activities</td>
                <td style="width: 15%">Predicate</td>
                <td>Description</td>
              </tr>
              <template v-if="dataReport.activities.length">
                <tr
                  style="height: 25px"
                  class="text-center"
                  v-for="(item, index) in dataReport.activities"
                  :key="index"
                >
                  <td style="height: 20px">{{ numberIndex(index) }}.</td>
                  <td>
                    {{ item.nama || '-' }}
                  </td>
                  <td>{{ item.predicate || '-' }}</td>
                  <td>
                    <!-- {{ checkString("extracurricular", item.nilai).description }} -->
                    {{ item.description || '-' }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr style="height: 25px" class="text-center">
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </template>
              <!-- <template v-else>
                <tr style="height: 25px" class="text-center">
                  <td>1.</td>
                  <td>LDKS kegiatan latihan dasar kepemimpinan siswa</td>
                  <td>A</td>
                  <td class="p-3">
                    Siswa dapat mengikuti kegiatan dengan baik. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Alias, vel voluptas. A, non dolores quidem voluptatum nihil laudantium unde nesciunt. Perspiciatis
                    voluptate quas magnam libero. Qui dignissimos facilis magni dolorem!
                  </td>
                </tr>
              </template> -->
            </table>
          </div>
          <li class="font-weight-bold text-uppercase">
            <h5>Achievement</h5>
          </li>
          <div class="mt-1">
            <table class="table-bordered mt-1 w-100" style="font-size: 11px">
              <tr style="height: 30px" class="text-center">
                <td style="width: 10%">No.</td>
                <td>Type</td>
                <td>Description</td>
              </tr>
              <template v-if="dataReport.achievement.length">
                <tr
                  style="height: 25px"
                  v-for="(item, i) in dataReport.achievement"
                  :key="item.id"
                  class="text-center"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.type || '-' }}</td>
                  <td>{{ item.description || '-' }}</td>
                </tr>
              </template>
              <template v-else>
                <tr style="height: 25px" class="text-center">
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr style="height: 25px" class="text-center">
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr style="height: 25px" class="text-center">
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </template>
            </table>
          </div>
          <li class="font-weight-bold text-uppercase">
            <h5>Attendance</h5>
          </li>
          <div class="mt-1">
            <!-- <table class="w-50 table  mt-1 bg-primary"> -->
            <!-- <tr>
                <td>Attendance</td>
              </tr> -->
            <!-- <tr>
                <td> -->
            <table class="table w-50 table-bordered" style="font-size: 11px">
              <tr>
                <td class="w-75">Sick</td>
                <td>:</td>
                <td>{{ dataReport.attendance.sick }}</td>
                <td>day(s)</td>
              </tr>
              <tr>
                <td class="w-75">Permit</td>
                <td>:</td>
                <td>{{ dataReport.attendance.permit }}</td>
                <td>day(s)</td>
              </tr>
              <tr>
                <td class="w-75">Unknown</td>
                <td>:</td>
                <td>{{ dataReport.attendance.absent }}</td>
                <td>day(s)</td>
              </tr>
            </table>
            <!-- </td>
              </tr>
            </table> -->
          </div>
          <li class="font-weight-bold text-uppercase">
            <h5>Teacher's Note</h5>
          </li>
          <div class="mt-1">
            <table class="table table-bordered mt-1" style="font-size: 11px">
              <tr class="text-center">
                <td>
                  <!-- {{
                    checkString(
                      "teacher_note",
                      scoring(dataReport.average_reports, [{ nilai: 75 }])
                        .remark
                    ).description
                  }} -->
                  <!-- {{ dataReport.teacher_note || '-' }} -->
                  {{ dataReport.teacher_note || '-' }}
                </td>
              </tr>
            </table>
          </div>
          <li class="font-weight-bold text-uppercase">
            <h5>Parent's Note</h5>
          </li>
          <div class="mt-2 d-flex align-items-stretch">
            <div class="border w-50"></div>
            <div class="mx-1"></div>
            <div class="border w-50">
              <h6 class="border-bottom text-center pb-2">Decision</h6>
              <div class="px-3">
                <h6 class="text-center font-size-10">
                  Based on the result that is achieved in semester
                  {{
                    [2, 'Genap'].includes(dataReport.tahun_ajaran.semester)
                      ? '1 and 2'
                      : '1'
                  }},
                </h6>
                <h6 class="text-center font-size-10">
                  the student is decided :
                  <span class="font-weight-bold">{{ decision }}</span>
                </h6>
              </div>
            </div>
          </div>
          <div
            class="ttd mb-2 mt-4 font-size-12 d-flex justify-content-between qrcode"
          >
            <img
              class="qrcode-image"
              v-if="base64QrCode"
              :src="base64QrCode"
              alt="qrcode"
            />
            <div class="">
              <h3 class="text-center font-size-12 invisible">.</h3>
              <h3 class="text-center font-size-12">Parent</h3>
              <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-5">
                ({{ dataReport.nama_orangtua || '-' }})
              </h3>
            </div>
            <div class="">
              <h3 class="text-center font-size-12 invisible">.</h3>
              <h3 class="text-center font-size-12">Class Teacher</h3>
              <div
                class="text-center mt-3 font-size-18"
                :class="dataReport.date_verify_walikelas ? '' : 'invisible'"
              >
                <img src="@/assets/checkbox.png" width="18" alt="verified" />
              </div>
              <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-1">
                ({{ dataReport.nama_walikelas || '-' }})
              </h3>
            </div>
            <div class="">
              <h3
                style="letter-spacing: 0.025em"
                class="text-center font-size-12"
              >
                Jakarta, {{ publicationDate }}
              </h3>
              <h3 class="text-center font-size-12">Principal</h3>
              <div
                class="text-center mt-3 font-size-18"
                :class="
                  dataReport.date_verify_kepala_sekolah ? '' : 'invisible'
                "
              >
                <img src="@/assets/checkbox.png" width="18" alt="verified" />
              </div>
              <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-1">
                ({{ dataReport.nama_kepala_sekolah || '-' }})
              </h3>
            </div>
          </div>
        </ol>
        <div
          v-if="print"
          style="
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 10px;
          "
        >
          <hr class="mb-1" />
          <Footer
            :nama="dataReport.murid.nama"
            :kelas="dataReport.murid.kelas"
            :semester="dataReport.tahun_ajaran.semester"
            :page="totalPageForSkillAndKnowledge + 2"
            :totalPage="totalPage"
          />
        </div>
      </div>
      <!-- Extra Page - RECAPITULATION OF REWARD & PUNISHMENT -->
      <div
        v-if="!page || (page && selectPage == 'rewardPunishment')"
        :style="
          format
            ? { ...format, height: format.height.split('px')[0] - 50 + 'px' }
            : {}
        "
        class="font-size-12"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <img
              :src="institusi ? institusi.logo : `/${configSchool.logo}`"
              width="120px"
              alt
            />
          </div>
          <div class="ml-5">
            <h3 class="text-uppercase font-size-28">
              RECAPITULATION OF REWARD & PUNISHMENT
            </h3>
          </div>
        </div>
        <hr />
        <table class="table table-borderless mt-3">
          <tr class="font-size-12">
            <td class="table-title py-1">School Name</td>
            <td class="py-1">:</td>
            <td class="table-value-left py-1">
              {{ institusi ? institusi.nama : configSchool.name2 }}
            </td>
            <td class="table-title py-1">Class</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.murid.kelas }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title py-1">Address</td>
            <td class="py-1">:</td>
            <td class="table-value-left py-1" style="width: 35%">
              {{ institusi ? institusi.alamat.lokasi : configSchool.address }}
            </td>
            <td class="table-title py-1">Academic</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.tahun_ajaran.tahun }}
              {{ dataReport.semester }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title font-size-12 py-1">Student Name</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.murid.nama }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title font-size-12 py-1">NIS</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.murid.nis || '-' }}
            </td>
          </tr>
          <tr class="font-size-12">
            <td class="table-title py-1">NISN</td>
            <td class="py-1">:</td>
            <td class="py-1">
              {{ dataReport.murid.nisn || '-' }}
            </td>
          </tr>
        </table>
        <hr />
        <h5 class="text-uppercase mb-4 subtext"><b>Reward & Punishment</b></h5>
        <table class="table-bordered w-100 text-center" style="font-size: 11px">
          <tr style="height: 30px" class="text-uppercase">
            <td>No.</td>
            <td style="width: 30%">Reward</td>
            <td>Score</td>
            <td>Frequency</td>
          </tr>
          <template v-if="checkReward">
            <tr
              style="height: 25px"
              v-for="i in rewardLength"
              :key="i"
              class="text-center"
            >
              <td>{{ i }}</td>
              <td>{{ dataReport[`reward_${i}`].nama || '-' }}</td>
              <td>{{ dataReport[`reward_${i}`].score || '-' }}</td>
              <td>{{ dataReport[`reward_${i}`].frequency || '-' }}</td>
            </tr>
          </template>
          <tr style="height: 25px" class="text-center" v-else>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </table>
        <br />
        <table class="table-bordered w-100 text-center" style="font-size: 11px">
          <tr style="height: 30px" class="text-uppercase">
            <td>No.</td>
            <td style="width: 30%">Punishment</td>
            <td>Score</td>
            <td>Frequency</td>
          </tr>
          <template v-if="checkPunishment">
            <tr
              style="height: 25px"
              v-for="i in punishmentLength"
              :key="i"
              class="text-center"
            >
              <td>{{ i }}</td>
              <td>{{ dataReport[`punishment_${i}`].nama || '-' }}</td>
              <td>{{ dataReport[`punishment_${i}`].score || '-' }}</td>
              <td>{{ dataReport[`punishment_${i}`].frequency || '-' }}</td>
            </tr>
          </template>
          <tr style="height: 25px" class="text-center" v-else>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </table>
        <br />
        <h5 class="text-uppercase mb-4">
          <b>Recapitulation of Reward & Punishment</b>
        </h5>
        <div
          class="d-flex justify-content-center align-items-center w-100 mt-1"
          style="height: 50px; border: 1px solid black"
        >
          <div>{{ dataReport.total_rekapitulasi_reward_punishment }}</div>
        </div>
        <div
          class="ttd mt-4 mb-2 font-size-10 d-flex justify-content-between align-items-end qrcode"
        >
          <img
            class="qrcode-image"
            v-if="base64QrCode"
            :src="base64QrCode"
            alt="qrcode"
          />
          <div class="">
            <h3 class="text-center font-size-12 invisible">.</h3>
            <h3 class="text-center font-size-12">Parent</h3>
            <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-5">
              ({{ dataReport.nama_orangtua || '-' }})
            </h3>
          </div>
          <div class="">
            <h3 class="text-center font-size-12 invisible">.</h3>
            <h3 class="text-center font-size-12">Class Teacher</h3>
            <div
              class="text-center mt-3 font-size-18"
              :class="dataReport.date_verify_walikelas ? '' : 'invisible'"
            >
              <!-- <a-icon type="check-square" style="color: #041930;"/> -->
              <!-- <check-icon /> -->
              <img src="@/assets/checkbox.png" width="18" alt="verified" />
            </div>
            <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-1">
              ({{ dataReport.nama_walikelas || '-' }})
            </h3>
          </div>
          <div class="">
            <h3
              style="letter-spacing: 0.025em"
              class="text-center font-size-12"
            >
              Jakarta, {{ publicationDate }}
            </h3>
            <h3 class="text-center font-size-12">Principal</h3>
            <div
              class="text-center mt-3 font-size-18"
              :class="dataReport.date_verify_kepala_sekolah ? '' : 'invisible'"
            >
              <img src="@/assets/checkbox.png" width="18" alt="verified" />
            </div>
            <h3 class="text-center font-size-12 font-weight-bold mt-3 pt-1">
              ({{ dataReport.nama_kepala_sekolah || '-' }})
            </h3>
            <!-- <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-5">({{generalReport.kepsek.nama ? generalReport.kepsek.nama : '-'}})</h3> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import moment from 'moment'
import QRCode from 'qrcode'
const Footer = () => import('./Footer')
// const CheckIcon = () => import('@/components/app/CheckIcon')
export default {
  components: { Footer },
  props: {
    configSchool: {
      type: Object,
    },
    dataReport: {
      type: Object,
    },
    page: {
      type: Boolean,
    },
    format: {
      type: Object,
      // required: true,
    },
    print: {
      type: Boolean,
      // required: true,
    },
    kumer: {
      type: Boolean,
    },
  },
  // components: { CheckIcon },
  data() {
    return {
      base64QrCode: null,
      textCaption: null,
      baseUrl: window.location.origin,
      selectPage: 1,
      tableGradeHeight: 90,
      rewardLength: 0,
      punishmentLength: 0,
    }
  },
  mounted() {
    if (this.dataReport) {
      // this.checkString('social', 'A')
      // console.log(this.page)
      this.generateBase64QrCode(`${this.dataReport.uuid}`)
      const guruWalas = this.dataReport.nama_walikelas
      const kepalaSekolah = this.dataReport.nama_kepala_sekolah
      this.textCaption = `This document was digitally signed by the principal${
        kepalaSekolah ? ` (${kepalaSekolah})` : ''
      }, and the class teacher${
        guruWalas ? ` (${guruWalas})` : ''
      }. No wet/physical signature required`

      const dataReportAsArray = Object.entries(this.dataReport) || []
      const dataReward = dataReportAsArray.filter(
        ([key]) =>
          key.includes('reward_') &&
          key !== 'total_rekapitulasi_reward_punishment',
      )
      this.rewardLength = dataReward.length
      const dataPunishment = dataReportAsArray.filter(
        ([key]) =>
          key.includes('punishment') &&
          key !== 'total_rekapitulasi_reward_punishment',
      )
      this.punishmentLength = dataPunishment.length
    }
  },
  methods: {
    moment,
    checkString(type, option) {
      const variableAvalaible = config.templateVariables
      let target = {
        alias: '-',
        description: '-',
      }
      const findTemplate = this.dataReport.template.find(
        (item) => item.type === type && item.option === option,
      )
      // console.log(`findTemplate`, findTemplate)
      if (findTemplate) {
        const matches = findTemplate.description.matchAll(/#(.*?)#/g)

        const isHavingCode = Array.from(matches, (x) => x[0])

        if (isHavingCode.length) {
          isHavingCode.forEach((el) => {
            const findCode = variableAvalaible.find(
              (variable) => variable.code.split(' ')[0] === el,
            )
            let replaceWith = ' '
            if (findCode) {
              if (el === '#STUDENT_NAME#') {
                replaceWith = this.dataReport.kelas_tahun_ajaran.murid.nama
              } else if (el === '#TEMPLATE_TYPE#') {
                replaceWith = findTemplate.type
              } else if (el === '#ALIAS#') {
                replaceWith = findTemplate.alias
              }
              findTemplate.description = findTemplate.description.replace(
                el,
                replaceWith,
              )
            }
            // console.log('loop', target, description)
          })
          // console.log('dont have code')
        }
        // console.log(target)
        target = findTemplate
      }
      // console.log('target', target)
      return target
    },
    async generateBase64QrCode(uuid) {
      try {
        const link = this.baseUrl + '/public/report-preview/' + uuid
        this.base64QrCode = await QRCode.toDataURL(link)
      } catch (err) {
        console.log(err)
      }
    },
    numberIndex(number, i) {
      if (i) {
        return ++number + 7
      } else {
        return ++number
      }
    },
    mapels(data, type) {
      if (!data.length) {
        return []
      }

      const find = data.filter((el) => el.mata_pelajaran.kelompok.nama === type)

      return find
    },
    scoring(score, kbm) {
      const scoringStatus = {
        remark: '-',
      }

      if (score !== null && kbm.length) {
        if (score > 89 && score <= 100) {
          scoringStatus.remark = 'A'
        } else if (score > 79 && score <= 89) {
          scoringStatus.remark = 'B'
        } else if (score >= kbm[0].nilai && score <= 79) {
          scoringStatus.remark = 'C'
        } else if (score < kbm[0].nilai) {
          scoringStatus.remark = 'D'
        }
      }

      console.log('scoring', score, scoringStatus)
      return scoringStatus
    },
    roundNumber(number) {
      if (!number) return null
      return Math.round(number)
    },
  },
  computed: {
    institusi() {
      return this.dataReport.institusi
    },
    rewards() {
      if (this.dataReport.report_reward_punishments?.length) {
        return this.dataReport.report_reward_punishments.filter(
          (reward) => reward.reward_punishment.type === 'reward',
        )
      }
      return []
    },
    punishments() {
      if (this.dataReport.report_reward_punishments?.length) {
        return this.dataReport.report_reward_punishments.filter(
          (punishment) => punishment.reward_punishment.type === 'punishment',
        )
      }
      return []
    },
    totalRewardPunishment() {
      let score = 100
      if (this.rewards.length || this.punishments.length) {
        this.rewards.forEach((reward) => {
          score += reward.reward_punishment.score * reward.frequency
        })
        this.punishments.forEach((punishment) => {
          score += punishment.reward_punishment.score * punishment.frequency
        })
      }
      return score
    },
    newGrade() {
      let res = 'Grade '
      if (this.dataReport?.kelas_tahun_ajaran) {
        const oldGrade = this.dataReport.kelas_tahun_ajaran.kela.tingkat
        if (oldGrade === 'VII') res += 'VIII (Eight)'
        else if (oldGrade === 'VIII') res += 'IX (Nine)'
        else if (oldGrade === 'IX') res = 'Senior High School'
      }

      return res
    },
    publicationDate() {
      return this.dataReport.tanggal_publikasi
        ? moment(this.dataReport.tanggal_publikasi, 'YYYY-MM-DD').format(
            'DD MMMM YYYY',
          )
        : moment().format('DD MMMM YYYY')
    },
    checkReward() {
      return (
        this.dataReport.reward_1?.nama ||
        this.dataReport.reward_2?.nama ||
        this.dataReport.reward_3?.nama
      )
    },
    checkPunishment() {
      return (
        this.dataReport.punishment_1?.nama ||
        this.dataReport.punishment_2?.nama ||
        this.dataReport.punishment_3?.nama
      )
    },
    totalPageForSkillAndKnowledge() {
      if (this.kumer) {
        return this.dataReport.knowledge_skill.knowledge.length
      } else {
        return (
          this.dataReport.knowledge_skill.knowledge.length +
          this.dataReport.knowledge_skill.skill.length
        )
      }
    },
    totalPage() {
      return 2 + this.totalPageForSkillAndKnowledge
    },
    spiritualAttitude() {
      return this.dataReport.spiritual_attitude || {}
    },
    socialAttitude() {
      return this.dataReport.social_attitude || {}
    },
    decision() {
      const decision = this.dataReport.decision
      return decision
    },
  },
}
</script>

<style lang="scss">
#card-body-wrapper {
  .subText {
    font-size: 1rem !important;
  }

  .table-bordered {
    border-collapse: inherit !important;
    border-color: black !important;

    & > tr {
      & > td {
        border-color: black !important;
        border-top-width: 0;
        border-left-width: 0;

        &:last-child {
          border-right-width: 0;
        }
      }

      &:last-child {
        & > td {
          border-bottom-width: 0;
        }
      }
    }
  }

  .ttd .qrcode-image {
    min-width: 50px;
    min-height: 50px;
    max-width: 100px;
    max-height: 100px;
  }

  @media (min-width: 768px) {
    .ttd .qrcode-image {
      width: 100px;
      height: 100px;
    }
  }

  .border,
  .border-bottom {
    border-color: black !important;
  }

  ol {
    li {
      h5 {
        padding-top: 7px;
      }
    }
  }
}
</style>
